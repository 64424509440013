<template>
  <v-flex lg3 md4 sm6 xs6 style="padding: 5px" v-if="data !== null">
    <v-card>
      <v-card-title style="text-align: center;display:block;">
        <i :class="'fad fa-' + icon"></i><br>
        <p style="white-space: nowrap;margin-bottom:0px;">{{ data }}</p>
      </v-card-title>
      <v-card-subtitle style="text-align:center;">{{ name }}</v-card-subtitle>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  props: {
    data: {
      type: String,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
</style>